/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:16:42 
 * @Last Modified by:   liuzhixiang 
 * @Last Modified time: 2021-02-06 14:16:42 
 */
<template>
  <div>
    <!-- <Banner></Banner> -->
    <online-question-bank :type="type"></online-question-bank>
  </div>
</template>

<script>
import onlineQuestionBank from "@/components/onlineQuestionBank";
import Banner from "./components/banner";
export default {
  data() {
    return {
      type:"question",
    };
  },
  components: {
    onlineQuestionBank,Banner
  },
  created() {},
  mounted() {
    
  },
  methods: {
   
  },
};
</script>

<style lang="less" scoped>
</style>