<template>
  <div class="questionBankList">
    <el-row
      :gutter="24"
      v-if="
        list.courses !== undefined &&
          list.courses.length > 0 &&
          list.types != 'teacher'
      "
    >
      <el-col :span="12" v-for="(li, i) in list.courses" :key="i">
        <div class="bgc_ffffff list" @click="goTo(li, list.types)">
          <div class="pic">
            <img v-lazy="li.CoverImg" alt="" />
          </div>
          <div class="cont">
            <div class="hd">
              <h2>{{ li.Name }}</h2>
              <div class="cinfo">
                <span>{{ li.PaperCount }}套试题</span>
              </div>
            </div>
            <ul class="detail">
              <li v-for="(lic, j) in li.CoursePaperList.slice(0, 3)" :key="j">
                <span
                  class="free-tag"
                  >{{
                    lic.PaperType == 0 ? '模拟' 
                    : lic.PaperType == 1 ? '真题' 
                    : lic.PaperType == 2 ? '专项' : ''}}</span
                ><a
                  target="_blank"
                  class="txt"
                  >{{ lic.Name }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24" v-else>
      <el-col :span="24"> 
        <no-data></no-data>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import noData from "@/components/noData";
export default {
  props: ["list"],
  data() {
    return {
      teacherWidth: 0,
    };
  },
  components: {
    noData
  },
  created() {},
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2,
      };
    },
  },
  mounted() {
    
  },
  methods: {
    goTo(obj, type) {
      let ob = {
        Id: obj.Id,
        Name: obj.Name,
        CoursePackage: obj.CoursePackage,
      };
      if (type == "course") {
        this.$router.push({ path: "/course/details", query: ob });
      } else if (type == "teacher") {
        this.$router.push({ path: "/home/teacherDetails", query: obj });
      } else if (type == "download") {
        this.$router.push({ path: "/dataDownload/list", query: ob });
      } else if (type == "question") {
        this.$router.push({ path: "/onlineQuestionBank/details", query: ob });
      }
    },
  },
};
</script>

<style lang="less" scoped>
body .el-scrollbar__wrap {
  overflow-x: hidden;
}
.questionBankList {
  margin-top: 40px;
  .el-row {
    .el-col {
      margin-bottom: 30px;
      .list {
        border: 1px solid #eee;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
        cursor: pointer;
        border-radius: 10px;
        transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
        display: flex;
        &:hover {
          transform: translateY(-6px);
          box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%);
        }
        .pic {
          width: 250px;
          padding: 18px;
          background: #f5f7fa;
          img {
            width: 100%;
            height: 120px;
            margin: 0 auto;
            display: block;
            border-radius: 12px;
          }
        }
        .cont {
          flex: 1;
          margin-left: 12px;
          margin-right: 22px;
          width: 58%;
          .hd {
            margin-top: 15px;
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            h2 {
              display: inline-block;
              vertical-align: middle;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
              max-width: 20em;
              font-size: 16px;
            }
            .cinfo {
              color: #aaa;
            }
          }
          .detail {
            li {
              margin-bottom: 10px;
              .free-tag {
                color: #25bb9b;
                border: 1px solid #25bb9b;
                border-radius: 4px;
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                font-size: 12px;
                padding: 1px 4px;
                margin-right: 5px;
              }
              .txt {
                display: inline-block;
                vertical-align: middle;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-wrap: normal;
                max-width: 90%;
                color: #888;
              }
            }
          }
        }
      }
    }
  }
}
</style>
